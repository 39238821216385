<template>
  <WeContainer card="">
    <!-- Top Card -->
    <WeCard class="mb-3 sticky-top shadow-sm">
      <div class="row align-items-center">
        <div class="col-12 col-md text-center text-md-left">
          <h5 class="mb-md-0">
            <i class="fas fa-sync"></i> {{ $t("bulkEquipSeoUpdate") }}
          </h5>
        </div>
        <div class="col-12 col-md-auto text-center">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-bind:permission="checkPermission"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>
    <!-- ./Top Card -->

    <!-- Filter -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-filter"></i>
        <span>Filtreleme</span>
      </h5>
      <hr />
      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Eqiupment Manufacturer -->
          <div class="d-block mb-2">
            <WeRowInput
              selectLabel="manufacturer"
              selectProp="manufacturer"
              form="searchSelect"
              size="sm"
              name="manufacturer"
              label="Ekipman Üreticisi"
              placeholder="Ekipman Üreticisi"
              v-model="filter.manufacturer"
              v-bind:clearable="true"
            />
          </div>
          <!-- Eqiupment Manufacturer -->

          <!-- Equipment Code -->
          <div class="d-block mb-2">
            <WeRowInput
              size="sm"
              name="skuNo"
              label="Ekipman Kodu"
              placeholder="Ekipman Kodu"
              v-model="filter.skuNo"
              v-bind:clearable="true"
            />
          </div>
          <!-- Equipment Code -->

          <!-- Engine Brand -->
          <div class="d-block mb-2">
            <WeRowInput
              size="sm"
              name="engineBrand"
              label="Motor Markası"
              placeholder="Motor Markası"
              v-model="filter.engineBrand"
              v-bind:clearable="true"
            />
          </div>
          <!-- Engine Brand -->

          <!-- Engine Model -->
          <div class="d-block mb-2">
            <WeRowInput
              size="sm"
              name="engineModel"
              label="Motor Modeli"
              placeholder="Motor Modeli"
              v-model="filter.engineModel"
              v-bind:clearable="true"
            />
          </div>
          <!-- Engine Model -->

          <!-- Engine Year -->
          <div class="d-block mb-2">
            <WeRowInput
              size="sm"
              name="engineYear"
              label="Motor Yılı"
              placeholder="Motor Yılı"
              v-model="filter.engineYear"
              v-bind:clearable="true"
            />
          </div>
          <!-- Engine Year -->

          <div class="mt-3">
            <span
              v-if="!filter_submit"
              class="btn btn-sm btn-primary"
              v-on:click="getEquipList"
              >Filtrele</span
            >
            <span v-else class="btn btn-sm btn-primary"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
            <span class="btn btn-danger btn-sm ml-2" v-on:click="onClear"
              >Temizle</span
            >
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <!-- We Seo Filter -->
          <div class="d-block">
            <WeSeoFilter v-model="filter" />
          </div>
          <!-- We Seo Filter -->
        </div>
      </div>
    </WeCard>
    <!-- ./Filter -->

    <!-- Update SEO -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-sync"></i>
        <span>Güncelleme</span>
      </h5>
      <hr />
      <WeMetaData
        class="ck-editor-seo"
        v-model="meta"
        v-bind:show-robots="false"
        v-bind:use-editor="true"
        v-bind:show-canonical="false"
        v-bind:mentions="getMentions"
        v-bind:show-limits="false"
      />
    </WeCard>
    <!-- ./Update SEO -->

    <WeCard v-if="bulkSeo.list.length" id="equipment-list">
      <h5 class="custom-card-title">
        <i class="far fa-copyright"></i>
        <span>Güncellenecek Ekipmanlar</span>
      </h5>
      <hr />
      <WeTable
        v-bind:index="false"
        v-bind:data="bulkSeo.list"
        v-bind:columns="columns"
      />
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "EquipmentSeo",
  data() {
    return {
      submit: false,
      filter_submit: false,
      route: "equipments",
      filter: {
        manufacturer: null,
        skuNo: null,
        engineBrand: null,
        engineModel: null,
        engineYear: null,
        is_active: null,
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
      },
      copyFilter: {},
      meta: {
        title:
          "<span class='mention' data-mention='@EquipmentMetaTitle'>@EquipmentMetaTitle</span>",
        keywords:
          "<span class='mention' data-mention='@EquipmentMetaKeywords'>@EquipmentMetaKeywords</span>",
        description:
          "<span class='mention' data-mention='@EquipmentMetaDescription'>@EquipmentMetaDescription</span>",
      },
      mentions: [
        {
          id: "@EquiptmentManufacturer",
          name: "Ekipman Üreticisi",
        },
        {
          id: "@EquiptmentCode",
          name: "Ekipman Kodu",
        },
        {
          id: "@EngineBrand",
          name: "Motor Markası",
        },
        {
          id: "@EngineModel",
          name: "Motor Modeli",
        },
        {
          id: "@EngineYear",
          name: "Motor Yılı",
        },
        {
          id: "@EquipmentMetaTitle",
          name: "Ekipman Meta Başlık",
        },
        {
          id: "@EquipmentMetaKeywords",
          name: "Ekipman Meta Anahtar Kelimeleri",
        },
        {
          id: "@EquipmentMetaDescription",
          name: "Ekipman Meta Açıklaması",
        },
      ],
      columns: [
        { name: "manufacturer", th: "Ekipman Üreticisi", type: "string" },
        { name: "engine_brand", th: "Motor Markası", type: "string" },
        { name: "engine_model", th: "Motor Modeli", type: "string" },
        { name: "engine_year", th: "Motor Yılı", type: "string" },
      ],
    };
  },
  methods: {
    ...mapActions("bulkSeo", ["load", "update", "getList"]),
    getEquipList(filter_submit) {
      this.filter_submit = filter_submit;
      this.copyFilter = helper.clone(this.filter);
      this.getList({
        route: this.route,
        filter: this.copyFilter,
        onFinish: () => {
          // Show button text insted of spinner
          this.filter_submit = false;

          if (this.bulkSeo.list && this.bulkSeo.list.length) {
            // Scroll to list
            document
              .getElementById("equipment-list")
              .scrollIntoView({ behavior: "smooth" });
          } else {
            this.$swal({
              title: "Başarısız",
              text: "Girilen filtrelerle eşleşen bir ekipman bulunamadı!",
              icon: "error",
            });
          }
        },
      });
    },
    submitForm() {
      if (!this.checkPermission) {
        permission.alert(this);
      } else {
        if (this.bulkSeo.list && this.bulkSeo.list.length) {
          this.$swal({
            title: "İşlemi Onaylıyor musunuz ?",
            html: `<b>${this.bulkSeo.list.length}</b> adet ekipman güncellenecek. Bu işlem geri alınamaz.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: "Güncelle",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.makeRequest();
            }
          });
        } else {
          this.$swal({
            title: "Geçersiz İşlem",
            text: "Lütfen güncellenecek ekipmanları filtreleyin",
            icon: "error",
          });
        }
      }
    },
    makeRequest() {
      this.submit = true;
      this.update({
        route: this.route,
        filter: this.copyFilter,
        requestData: this.meta,
        onSuccess: (result) => {
          this.$swal({
            title: "İşlem Başarılı",
            text:"Güncelleme işlemi kuyruğa alındı tamamlandığında mail adreinize bildirim gönderilecektir.",
            icon: "success",
            confirmButtonText: "Tamam",
            onClose: () => this.getEquipList(),
          });
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onClear() {
      this.filter = {
        brand: null,
        is_active: null,
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
      };
      this.copyFilter = {};
      this.getEquipList(false);
    },
  },
  computed: {
    ...mapState(["bulkSeo"]),
    checkPermission() {
      return permission.check("brand", "u");
    },
    getMentions() {
      return [...this.bulkSeo.globalMentions, ...this.mentions];
    },
  },
  mounted() {
    this.load();
  },
};
</script>
